import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"


// markup
class IndexPage extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const siteDescription = data.site.siteMetadata.description
    const books = data.allBooksXlsxSheet1.edges

    var todoBooks = books.reduce(function(result, {node}) {
      if (node.status === 'todo') {
        result.push(node);
      }
      return result;
    }, []);

    var todo = todoBooks.map((node) => ({
      "id": node.id,
      "title": node.title,
      "author": node.author,
      "backgroundColor": node.backgroundColor,
      "authorColor": node.authorColor,
      "authorSize": node.authorSize,
      "authorTop": node.authorTop,
      "authorLeft": node.authorLeft,
      "titleColor": node.titleColor,
      "titleSize": node.titleSize,
      "titleTop": node.titleTop,
      "titleLeft": node.titleLeft,
      "titleWidth": node.titleWidth,
      "depth": node.depth / 0.7,
      "width": node.width,
      "height": node.height * 2,
    }))

    var finishedBooks = books.reduce(function(result, {node}) {
      if (node.status === 'finished') {
        result.push(node);
      }
      return result;
    }, []);

    var finished = finishedBooks.map((node) => ({
      "id": node.id,
      "title": node.title,
      "author": node.author,
      "backgroundColor": node.backgroundColor,
      "authorColor": node.authorColor,
      "authorSize": node.authorSize,
      "authorTop": node.authorTop,
      "authorLeft": node.authorLeft,
      "titleColor": node.titleColor,
      "titleSize": node.titleSize,
      "titleTop": node.titleTop,
      "titleLeft": node.titleLeft,
      "titleWidth": node.titleWidth,
      "depth": node.depth / 0.7,
      "width": node.width,
      "height": node.height * 2,
    }))

  return (
    <Layout location={this.props.location} title={siteTitle}>
        <Seo
          title="All Books"
          keywords={[`blog`, `gatsby`, `javascript`, `react`]}
        />
        <p className="description">{siteDescription}</p>
        <div className='container'>
          <div className='col-md-6'>
            <h2>Books I have to read</h2>
            <ul id='todo' className=''>
            {todo.map(( node ) => {
              const title = node.title
              const author = node.author
              const bgColor = node.backgroundColor
              const height = node.height
              const width = node.depth / 0.7
              const authorColor = node.authorColor
              const authorSize = node.authorSize
              const authorTop = node.authorTop
              const authorLeft = node.authorLeft
              const titleColor = node.titleColor
              const titleSize = node.titleSize
              const titleTop = node.titleTop
              const titleLeft = node.titleLeft
              const titleWidth = node.titleWidth
              return (
                <li key={node.id} style={{backgroundColor: bgColor, width: height, height: width}}>
                  <span className='title' style={{width: titleWidth, color: titleColor, fontSize: titleSize , top: titleTop, left: titleLeft}}>{title}</span>
                  <span className='author' style={{color: authorColor, fontSize: authorSize , top: authorTop, left: authorLeft}}>{author}</span>
                </li>
              )
            })}
            </ul>
          </div>
          <div className='col-md-6'>
            <h2>Books I have finished reading</h2>
            <ul id='finished' className=''>
            {finished.map(( node ) => {
              const title = node.title
              const author = node.author
              const bgColor = node.backgroundColor
              const height = node.height
              const width = node.depth / 0.7
              const authorColor = node.authorColor
              const authorSize = node.authorSize
              const authorTop = node.authorTop
              const authorLeft = node.authorLeft
              const titleColor = node.titleColor
              const titleSize = node.titleSize
              const titleTop = node.titleTop
              const titleLeft = node.titleLeft
              const titleWidth = node.titleWidth
              return (
                <li key={node.id} style={{backgroundColor: bgColor, width: height, height: width}}>
                  <span className='title' style={{width: titleWidth, color: titleColor, fontSize: titleSize , top: titleTop, left: titleLeft}}>{title}</span>
                  <span className='author' style={{color: authorColor, fontSize: authorSize , top: authorTop, left: authorLeft}}>{author}</span>
                </li>
              )
            })}
            </ul>
          </div>
        </div>
      </Layout>
  )
    }
}

export default IndexPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    allBooksXlsxSheet1(sort: { fields: [sortAuthor, date], order: ASC }) {
      edges {
        node {
          id
          isbn
          title
          author
          sortAuthor
          date
          status
          backgroundColor
          authorColor
          authorSize
          authorTop
          authorLeft
          titleColor
          titleSize
          titleTop
          titleLeft
          titleWidth
          depth
          width
          height
        }
      }
    }
  }
`